.about {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: auto;
    flex-direction: column;
    width: 100vw;
    overflow-x: hidden;
}

.about>div {
    padding-right: 10%;
    padding-left: 10%;
    margin-top: 90px;
}

.about>.manifesto {
    margin-top: 160px;
}

.title h2 {
    font-size: 60px;
    font-family: 'Jost', sans-serif;
    font-weight: 300;
    cursor: pointer;
    margin-bottom: 25px;
    cursor: auto;
    text-align: start;
}

.description p {
    font-size: 17px;
    font-family: 'Jost', sans-serif;
    font-weight: 200;
    letter-spacing: 0.05rem;
    text-align: start;
    margin-bottom: 20px;
}

.description>.outro {
    margin-top: 70px;
}

.treasury ul {
    padding-left: 35px;
}

li::marker {
    content: initial;
}

a {
    display: inline-block;
}

.about a::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: #fff;
    transition: width .3s;
    margin: 0;
    padding: 0;
}

.about a:hover::after {
    width: 100%;
    transition: width .3s;
}

@media only screen and (max-width: 600px) {
    .addr-text {
        font-size: 13px;
    }
}
