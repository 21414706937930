.c-home {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    .c-home-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        .c-title {
            font-family: 'UnifrakturMaguntia', cursive;
            color: red;
            font-size: 120px;
            cursor: pointer;
        }
    }
}