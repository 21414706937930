@import url('https://fonts.googleapis.com/css2?family=UnifrakturMaguntia&display=swap');
@import "./variables.scss";
@import "./breakpoints.scss";
@import "./components/cHome.scss";
body,
html {
    // visibility: hidden;
    user-select: none;
    background: $black;
    overscroll-behavior: none;
    margin: 0;
    color: white;
    overflow-x: hidden;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    .scrollbar-thumb {
        background: #999999;
        @include media ('<=tablet') {
            display: none;
        }
    }
    .scrollbar-track-y {
        background: none;
        @include media ('<=tablet') {
            background: none;
            width: 0;
        }
    }
}

a {
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.App {
    text-align: center;
    position: relative;
}

.container {
    width: 1560px;
    min-width: 1560px;
    margin: 0 auto;
    height: 100%;
    @include media("<=1560px") {
        width: 1280px;
        min-width: 1280px;
    }
    @include media("<=desktop") {
        width: 1080px;
        min-width: 1080px;
    }
    @include media("<=1080px") {
        width: 100%;
        min-width: 100%;
    }
    .row {
        display: flex;
        height: 100%;
        padding: 0 32px;
        @include media("<=phone") {
            padding: 0 16px;
        }
    }
    .v-center {
        align-items: center;
    }
    .space-between {
        justify-content: space-between;
    }
}

.container-fluid {
    width: 100%;
    height: 100%;
    .row {
        display: flex;
        height: 100%;
    }
}

// intro-overlay
.intro-overlay {
    .top {
        height: 50vh;
        height: calc(var(--vh, 1vh) * 50);
        position: absolute;
        width: 100%;
        z-index: 8;
        .overlay-top {
            position: absolute;
            height: 100%;
            width: 33.333vw;
            background: $black;
            bottom: 0;
            left: 0;
            right: 0;
            @include media('<=tablet') {
                width: 100vw;
            }
            &:nth-child(2) {
                left: 33.333%;
                @include media('<=tablet') {
                    display: none;
                }
            }
            &:nth-child(3) {
                left: 66.666%;
                @include media('<=tablet') {
                    display: none;
                }
            }
        }
    }
    .bottom {
        height: 50vh;
        height: calc(var(--vh, 1vh) * 50);
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 8;
        .overlay-bottom {
            position: absolute;
            height: 100%;
            width: 33.333vw;
            background: $black;
            bottom: 0;
            right: 66.666%;
            @include media('<=tablet') {
                right: 0;
                width: 100vw;
            }
            &:nth-child(2) {
                right: 33.333%;
                @include media('<=tablet') {
                    width: 100vw;
                    top: 100%;
                    right: 0;
                }
            }
            &:nth-child(3) {
                right: 0;
                @include media('<=tablet') {
                    width: 100vw;
                    top: 200%;
                }
            }
        }
    }
}