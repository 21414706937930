.home {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.home-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-logo img {
    width: 60%;
    cursor: pointer;
}

h1 {
    font-size: 120px;
    font-family: 'Jost', sans-serif;
    font-weight: 300;
    cursor: pointer;
}

a {
    text-decoration: none;
}

@media only screen and (max-width: 600px) {
    .home-logo img {
        width: 330px;
    }
}