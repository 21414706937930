.navbar {
    width: 100%;
    position: fixed;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-item {
    position: fixed;
    top: 30px;
    font-family: 'Jost', sans-serif;
    letter-spacing: 0.1rem;
    font-size: 20px;
    cursor: pointer;
    font-weight: 300;
    text-transform: uppercase;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: opacity 0.2s ease;
}

.about-button {
    right: 40px;
    /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
}

.home-button {
    left: 30px;
    /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
}

.auction-button {
    /* left: 130px; */
    left: 30px;
    font-size: 15px;
    /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
}

.home-button:hover,
.auction-button:hover {
    opacity: 0.7;
}

.nav-item img {
    width: 55px;
}

a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 600px) {
    .navbar {
        height: 75px;
    }
    .nav-item img {
        margin-top: -0.2rem;
        width: 50px;
    }
}