.footer {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 50px;
    font-family: 'Jost', sans-serif;
    justify-content: space-between;
    align-items: center;
}

.footer>* {
    flex: 1 100%;
}

.l-footer {
    flex: 1;
    margin-bottom: 2em;
}

.r-footer {
    flex: 1;
    margin-left: 55px;
}

.r-footer h2 {
    font-size: 70px;
    font-family: 'Jost', sans-serif;
    font-weight: 300;
    cursor: pointer;
    margin-bottom: 10px;
    cursor: auto;
    text-align: start;
}

.l-footer img {
    width: 550px;
}

.r-footer p {
    font-weight: 200;
    font-size: 22px;
    letter-spacing: 00.05rem;
    text-align: start;
}

.footer ul {
    list-style: none;
    padding-left: 0;
    margin-top: 30px;
}

.r-footer li {
    line-height: 2em;
    font-weight: 300;
    font-size: 19px;
    text-align: left;
    letter-spacing: 00.05rem;
}

.footer a {
    text-decoration: none;
}

.socials {
    display: flex;
}

@media only screen and (min-width: 600px) {
    .r-footer li {
        visibility: visible;
    }
    .socials-icon {
        visibility: hidden;
    }
}

@media only screen and (max-width: 600px) {
    .footer {
        padding-bottom: 110px;
    }
    .r-footer {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        justify-content: center;
        align-items: center;
    }
    .l-footer img {
        width: 300px;
    }
    .r-footer h2 {
        font-size: 60px;
    }
    .r-footer p {
        font-weight: 300;
        font-size: 20px;
        margin-left: -20px;
    }
    .r-footer li {
        visibility: hidden;
    }
    .socials {
        margin-left: -13rem;
    }
    .socials-icon {
        visibility: visible;
        font-size: 1.9rem;
        margin: 10px 0px 0 20px;
    }
}